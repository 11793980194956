<template>
  <div>
    <CAlert color="secondary" :fade="false">
      系統已包含Facebook像素標準事件，若啟用轉換API時，系統會在取消訂單和訂單退款時分別傳送自訂事件「OrderCancel」及「Refund」，用於評估顧客購買後的是否放棄訂單的指標。
    </CAlert>
    <CInput v-model="TrackingData.FP" :label="$t('System/Tracking.Labels.FP')" @change="() => TrackingData.FB_DATASET_ID = TrackingData.FP" />
    <div class="form-group">
      <label for="LineNotifyEnable" class="d-block">{{ $t('System/Tracking.Labels.FB_CAPI_ENABLE') }}</label>
      <CSwitch id="LineNotifyEnable" size="sm" color="success" :checked.sync="TrackingData.FB_CAPI_ENABLE" />
    </div>
    <CTextarea rows="4" v-if="TrackingData.FB_CAPI_ENABLE === true" v-model="TrackingData.FB_CAPI_TOKEN" :label="$t('System/Tracking.Labels.FB_CAPI_TOKEN')" />
  </div>
</template>

<script>
export default {
  name: 'FP',
  props: {
    TrackingData: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
