<template>
  <CAlert color="secondary" :fade="false" class="mb-0">
    請參考<a class="text-info" href="https://docs.omnichat.ai/features/tong-xun-qu-dao/wang-zhan-dui-hua-cha-jian/install/google-tag-manager">安裝指引<i class="fas fa-external-link-alt mx-1" style="font-size: 10px" /></a>將對話插件加入至 Google Tag Manager 的容器中。
  </CAlert>
</template>

<script>
export default {
  name: "OMNICHAT"
}
</script>

<style scoped>

</style>
