<template>
  <main id="Tracking">
    <CCard>
      <CCardBody>
        <div class="mb-4">
          <h5 class="text-info">
            追蹤代碼設定
          </h5>
          <div>
            使用第三方追蹤工具可幫助您使用數據分析追蹤顧客在商店的活動行為。
          </div>
        </div>
        <CRow>
          <template v-for="provider in Providers">
            <CCol :key="provider" lg="3" md="4" sm="6" style="cursor: pointer" @click="OpenProviderModal(provider)">
              <CWidgetBrand color="white" class="position-relative" addHeaderClasses="border-0">
                <div class="p-5 text-center">
                  <img :src="`https://cdn.marketingless.com/public/vendor/${provider}.png`" class="w-100" style="height: 60px;object-fit: contain" alt="">
                </div>
                <template #body>
                  <div class="px-3 pb-3 text-center font-weight-bold">
                    {{ $t('System/Tracking.' + provider) }}
                  </div>
                </template>
              </CWidgetBrand>
            </CCol>
          </template>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="mb-4">
          <h5 class="text-info">
            {{ $t('Navigation.Marketing/ProductFeed') }}
          </h5>
          <div>
            可使用於Google Merchant Center、Facebook DPA動態廣告及Omnichat產品目錄，讓第三方使用現有商品目錄做投放廣告及行銷推播等用途。
          </div>
        </div>
        <div class="mt-4">
          <CInput :value="`https://${$store.state.project.Domain}/product/feed`" :label="$t('System/Tracking.Feed')" disabled>
            <template #append>
              <CButton type="button" color="info" @click="CopyToClipboard(`https://${$store.state.project.Domain}/product/feed`)">
                <CIcon name="cil-copy" class="c-icon-custom-size mr-2 my-0" />{{ $t('Global.Copy') }}
              </CButton>
            </template>
          </CInput>
        </div>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="mb-4">
          <h5 class="text-info">
            {{ $t('Navigation.Marketing/Sitemap') }}
          </h5>
          <div>
            將Sitemap提交至<a class="text-info" href="https://search.google.com/search-console">Google Search Console</a>可讓Google搜尋引擎更快收錄頁面至搜尋結果中。
          </div>
        </div>
        <div class="mt-4">
          <CInput :value="`https://${$store.state.project.Domain}/sitemap.xml`" :label="$t('System/Tracking.Sitemap')" disabled>
            <template #append>
              <CButton type="button" color="info" @click="CopyToClipboard(`https://${$store.state.project.Domain}/sitemap.xml`)">
                <CIcon name="cil-copy" class="c-icon-custom-size mr-2 my-0" />{{ $t('Global.Copy') }}
              </CButton>
            </template>
          </CInput>
        </div>
      </CCardBody>
    </CCard>
    <CModal id="ProviderAModal" class="ActionModel" size="lg" :show.sync="ProviderModal" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">設定{{ $t('System/Tracking.' + CurrentProvider) }}</h5>
          <CButtonClose @click="ProviderModal = false"/>
        </header>
      </template>
      <div class="p-3 border">
        <component :is="Component" :TrackingData="TrackingData" />
      </div>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="ProviderModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="SetData(CurrentProvider)" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "追蹤與曝光"
  }
}
</route>

<script>
export default {
  name: 'Tracking',
  layout: 'manage',
  components: {

  },
  data () {
    return {
      Features: [],
      Loading: false,
      Submit: false,
      Token: '',
      AllowSet: [
        'GTM', 'FP'
      ],
      Providers: [
        'GA', 'GTM', 'FP', 'OMNICHAT', 'DCARD'
        // 'GAD',
        // 'CLA',
      ],
      Component: null,
      ProviderModal: false,
      CurrentProvider: false,
      TrackingData: {
        GA: '',
        GTM: '',
        FP: '',
        GAW: {
          TrackingNum: '',
          BrowseTag: '',
          PurchaseTag: ''
        },
        GRM: '',
        YAd: {
          ProjectID: '',
          TrackingID: ''
        }
      }
    }
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
      this.Features = PermissionSession.Features.System.Tracking
      return Promise.all([
        this.GetData(),
        this.GetToken()
      ])
    },
    GetToken() {
      return this.$store.dispatch('InnerRequest', {
        url: '/web/token2',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project
        }
      }).then(({data}) => {
        this.Token = data.Data
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/Tracking',
        method: 'post'
      }).then(({data}) => {
        if (Object.keys(data).length > 0) this.TrackingData = data
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetData() {
      if (!this.AllowSet.includes(this.CurrentProvider)) {
        this.ProviderModal = false
        return Promise.resolve()
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/Tracking',
        method: 'post',
        data: {Tracking: this.TrackingData}
      }).then(() => {
        this.ProviderModal = false
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    OpenProviderModal(provider) {
      this.Component = null
      this.ProviderModal = true
      this.CurrentProvider = provider
      this.Loading = true
      setTimeout(() => {
        this.Component = require('@/components/tracking/' + provider).default
        this.Loading = false
      }, 500)
    },
    CopyToClipboard (string) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/CopySuccess'),
          type: 'success'
        })
        return navigator.clipboard.writeText(string)
      }
      return Promise.reject('The Clipboard API is not available.');
    }
  }
}
</script>
