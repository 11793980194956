<template>
  <div>
    <CAlert color="secondary" :fade="false">
      建議使用GTM管理多個追蹤代碼及附加功能，方便您統整與管理資源。<a class="text-info" href="https://tagmanager.google.com/">開啟Google Tag Manager<i class="fas fa-external-link-alt mx-1" style="font-size: 10px" /></a>
    </CAlert>
    <CInput v-model="TrackingData.GTM" :label="$t('System/Tracking.Labels.GTM')" />
  </div>
</template>

<script>
export default {
  name: 'GTM',
  props: {
    TrackingData: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
