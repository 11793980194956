<template>
  <div>
    <CAlert color="secondary" :fade="false">
      系統已在創建專案時建立 Google Analytics 4 並埋設完成，同時也具備追蹤電子商務事件，請<router-link class="text-info" to="/feedback">與我們聯絡</router-link>或加入<a class="text-success" href="https://page.line.me/umeans">LINE官方帳號</a>取得檢視報表、管理設定及開啟加強型評估的資源權限。若您需要自行加入額外的 Google Analytics 代碼，請使用 Google Tag Manager 埋設於容器內。
    </CAlert>
    <CInput :value="$store.state.project.AppSetting && $store.state.project.AppSetting.measurementId || TrackingData.GA" :disabled="true" :label="$t('System/Tracking.Labels.GA')" />
  </div>
</template>

<script>
export default {
  name: 'GA',
  props: {
    TrackingData: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
