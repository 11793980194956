<template>
  <CAlert color="secondary" :fade="false" class="mb-0">
    系統已包含 Dcard Web AD Tracking 標準事件，請參考<a class="text-info" href="https://medium.com/dcard-ads/dcard-web-ad-tracking-sdk-gtm-%E5%9F%8B%E8%A8%AD%E8%AA%AA%E6%98%8E-b43acd5d663e">埋設說明<i class="fas fa-external-link-alt mx-1" style="font-size: 10px" /></a>在 GTM 全域設定 Dcard Web AD Tracking SDK 與 Page View 事件。
  </CAlert>
</template>

<script>
export default {
  name: "DCARD"
}
</script>

<style scoped>

</style>
